.title {
    font-weight: 800;
    font-size: 2.5em;
    display: block;
    font-family: Poppins, sans-serif;
    margin-bottom: 20px;
  }
  .paragraph {
    display: block;
    font-weight: 300;
    font-size: 1.5em;
    font-family: Poppins, sans-serif;
  }
  .partnerbtn {
    background-color: black;
    color: white;
    width: 150px;
    font-size: 20px;
    padding: 8px;
    border-radius: 5px;
    border: none;
    font-family: Poppins, sans-serif;
    cursor: pointer;
  }
  .btn{
    text-align: center;
  }
  @media screen and (max-width: 1200px) {
    .title {
      font-size: 2em;
    }
    .paragraph {
      font-size: 1.2em;
    }
  }
  @media screen and (max-width: 992px) {
    .title {
      font-size: 1.5em;
    }
    .paragraph {
      font-size: 1em;
    }
  }
  @media screen and (max-width: 767px) {
    .title {
      font-size: 2.5em;
    }
    .paragraph {
      font-size: 1.3em;
    }
    .overlay p, .overlayPune p {
      font-size: 17px;
    }
  }
  @media screen and (max-width: 576px) {
    .title {
      font-size: 2em;
    }
    .paragraph {
      font-size: 1.3em;
    }
  }