.LogoAlignment {
  height: 5em;
  width: 5em;
}

.LogoAlignmentRight {
  height: 5.5em;
  width: 5.5em;
}

.logo {
  width: 100%;
  height: auto;
}

@media only screen and (max-width: 1200px) {
  .LogoAlignment {
    height: 4.5em;
    width: 4.5em;
  }

  .LogoAlignmentRight {
    height: 4.5em;
    width: 5em;
  }
}

@media only screen and (max-width: 992px) {
  .LogoAlignment {
    height: 4.1em;
    width: 4.1em;
  }

  .LogoAlignmentRight {
    height: 4.1em;
    width: 4.6em;
  }
}

@media only screen and (max-width: 890px) {
  .LogoAlignment {
    height: 4.5em;
    width: 4.5em;
  }

  .LogoAlignmentRight {
    height: 4.5em;
    width: 5em;
  }
}
