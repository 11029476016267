/* .bannerContent {
  padding: 50px 0 50px 0;
  background-image: url("../../Assets/Welcome_Banner/background.png");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: 50% 52%;
  background-origin: content-box;
}

.bannerImage,
.bannerImageMobile {
  width: 100%;
  height: auto;
}

.bannerRow {
  position: relative;
}

.imageWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 500px;
  position: absolute;
  top: 75%;
  left: 44%;
  transform: translate(-50%, -50%);
}

.signIn {
  width: 13vw;
  margin-right: -1.8em;
}

.watchDemo {
  width: 13vw;
}

@media only screen and (max-width: 768px) {
  .bannerContent {
    padding: 20px 0;
    background: none;
  }

  .imageWrapper {
    width: 100vw;
    top: 85%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  .signIn,
  .watchDemo {
    width: 40vw;
  }
} */

.overlay {
  position: absolute;
  background: rgba(12, 12, 12, 0.6);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  inset: 0;
}

.maintext {
  font-size: 3.4em !important;
  /* font-family: "Montserrat", sans-serif !important; */
  font-optical-sizing: auto;
  font-weight: 600;
  letter-spacing: -1px;
  color: #fff;
  text-align: center;
  font-family: "Poppins", sans-serif;
}
.nexttext {
  font-size: 2.4em;
  font-optical-sizing: auto;
  font-weight: 200;
  color: #fff;
  text-align: center;
  font-family: "Poppins", sans-serif;
}
.appointmentbtn {
  background: white;
  color: #000;
  margin-top: 1em;
  border-radius: 5px;
  cursor: pointer;
  font-family: "Poppins", sans-serif;
  padding: 10px;
  font-weight: 500;
  width: 250px;
  border: none;
  font-size: 1.3em;
}
.appointmentbtn:hover{
  background-color: black;
  color: white;
}
@media screen and (max-width: 768px) {
  .maintext {
    font-size: 1.5em !important;
  }
  .nexttext {
    font-size: 1.2em !important;
  }
}
@media screen and (max-width: 576px) {
  .maintext {
    font-size: 1em !important;
    margin-top: 30px !important;
  }
  .nexttext {
    font-size: 1em !important;
  }
  .appointmentbtn{
    padding: 8px;
    font-weight: 500;
    width: 180px;
    border: none;
    font-size: 1em;
  }
}
@media screen and (max-width: 375px) {
  .maintext {
    display: none !important;
  }
  .nexttext {
    display: none !important;
  }
}
