.formInput {
  border: 0.5px solid rgba(0, 0, 0, 0.5);
  border-radius: 2px;
  padding: 0.8em 1em;
  font-family: "Poppins", sans-serif;
}

.getInTouchForm {
  background-image: linear-gradient(
      to bottom,
      transparent 0%,
      /* transparent 20%, */ #000 100%
    ),
    url(../../Assets/getInTouch.png);
  background-origin: content-box;
  background-size: cover;
  background-repeat: no-repeat;
  /* background-position: center; */
}

.submitbtn {
  color: #000;
  border: none;
  border-radius: 7px;
  padding: 10px 60px;
  margin-bottom: 22px;
  background-color: #fff;
  font-weight: 700;
  font-size: 24px !important;
}

.getIntouchTitle {
  font-size: 48px;
  font-weight: 600;
  color: white;
  font-family: "Poppins", sans-serif;
  margin-bottom: 25px;
  margin-top: 115px;
  text-shadow: 0px 1px 12px rgba(0, 0, 0, 0.38);
}

.formInput {
  border: none;
}

.formInput::placeholder {
  color: rgb(154, 154, 154);
  font-weight: 500;
  font-size: 20px;
}

@media only screen and (max-width: 768px) {
  .submitbtn {
    padding: 10px 50px;
    margin-bottom: 22px;
    font-size: 22px !important;
  }

  .getIntouchTitle {
    font-size: 44px;
    margin-bottom: 20px;
    margin-top: 45px;
  }

  .formInput {
    border: none;
  }

  .formInput::placeholder {
    font-size: 18px;
  }
}

@media only screen and (max-width: 576px) {
  .submitbtn {
    padding: 10px 40px;
    margin-bottom: 20px;
    font-size: 21px !important;
  }

  .getIntouchTitle {
    font-size: 42px;
    margin-bottom: 20px;
    margin-top: 45px;
  }

  .formInput {
    border: none;
  }

  .formInput::placeholder {
    font-size: 16px;
  }
}
