.contentStyle {
  /* margin-top :64px; */
  text-align: center;
  height: 95%;
  /* line-height: 120px; */
  color: #000000;
  background-color: #ffffff;
}

.footerStyle {
  text-align: center;
  color: #fff;
  font-size: 10px;
  font-family: "Poppins", sans-serif;
  background-color: #3F0B00;
  padding: 24px 20px;
}

.layoutStyle {
  min-height: 100vh;
}

.scrollContainer {
  position: absolute;
  top: 0;
  right: 0;
  height: 100%;
}

.backToTopIcon {
  font-size: 20px;
  font-family: "Poppins", sans-serif;
  font-weight: 600 !important;
  display: flex !important;
  justify-content: center;
  align-items: center;
  /* color: #333; */
}

:where(.backto) {
  background-color: #3F0B00 !important;
}

.backtotop {
  display: flex !important;
  justify-content: center;
  align-items: center;
  bottom: 80px;
  height: 40px;
  width: 40px;
  background-color: #3F0B00 !important;
}


.scrollContainer a {
  position: fixed;
  bottom: 20px;
  z-index: 11;
  border: 1px solid;
  padding: 15px;
  border-radius: 50%;
  right: 20px;
  background-color: "#63005f";
}
