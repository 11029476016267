.imageWrapper {
  position: relative;
}

.textContainer {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: rgba(255 255, 255, 0.8);
  border-radius: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  /* width: 1050px; */
  width: 80%;
  height: 350px;
  padding: 50px;
}

.slider {
  padding: 30px;
}

.title {
  font-family: "Poppins", sans-serif !important;
  font-size: 32px !important;
  font-weight: 700 !important;
  line-height: 45px;
  width: 80%;
}

@media screen and (max-width: 1400px) {
  .textContainer {
    width: 77%;
    height: 330px;
    padding: 50px;
  }

  .title {
    font-size: 30px !important;
    font-weight: 700 !important;
    line-height: 45px;
    width: 80%;
  }
}

@media screen and (max-width: 1300px) {
  .textContainer {
    width: 75%;
    height: 330px;
    padding: 50px;
  }
}

@media screen and (max-width: 1200px) {
  .slider {
    padding: 0px;
  }

  .textContainer {
    width: 69%;
    height: 290px;
    padding: 50px;
  }

  .title {
    font-size: 28px !important;
    font-weight: 700 !important;
    line-height: 40px;
    width: 80%;
  }
}

@media screen and (max-width: 1100px) {
  .textContainer {
    width: 66%;
    height: 290px;
    padding: 50px;
  }
}

@media screen and (max-width: 992px) {
  .slider {
    padding: 0px;
  }

  .textContainer {
    width: 75%;
    height: 270px;
    padding: 30px;
  }

  .title {
    font-size: 26px !important;
    font-weight: 700 !important;
    line-height: 40px;
    width: 80%;
  }
}

@media screen and (max-width: 900px) {
  .textContainer {
    width: 72%;
    height: 270px;
    padding: 30px;
  }
}

@media screen and (max-width: 800px) {
  .textContainer {
    width: 70%;
    height: 230px;
    padding: 30px;
  }
}

@media screen and (max-width: 768px) {
  .textContainer {
    width: 80%;
    height: 230px;
    padding: 30px;
  }

  .title {
    font-size: 26px !important;
    font-weight: 700 !important;
    line-height: 35px;
    width: 80%;
  }
}

@media screen and (max-width: 700px) {
  .textContainer {
    width: 78%;
    height: 190px;
    padding: 30px;
  }

  .title {
    font-size: 24px !important;
    font-weight: 700 !important;
    line-height: 35px;
    width: 80%;
  }
}

@media screen and (max-width: 700px) {
  .image {
    width: 100% !important;
    height: 350px !important;
    object-fit: cover;
    border-radius: 15px !important;
  }

  .textContainer {
    width: 78%;
    height: 230px;
    padding: 30px;
  }

  .title {
    font-size: 22px !important;
    font-weight: 700 !important;
    line-height: 28px;
    width: 80%;
  }
}

@media screen and (max-width: 576px) {
  .image {
    width: 100% !important;
    height: 310px !important;
    object-fit: cover;
    border-radius: 15px !important;
  }

  .textContainer {
    width: 84%;
    height: 210px;
    padding: 30px;
  }

  .title {
    font-size: 20px !important;
    font-weight: 700 !important;
    line-height: 26px;
    width: 80%;
  }
}

@media screen and (max-width: 425px) {
  .image {
    width: 100% !important;
    height: 310px !important;
    object-fit: cover;
    border-radius: 15px !important;
  }

  .textContainer {
    width: 84%;
    height: 225px;
    padding: 30px;
  }

  .title {
    font-size: 17px !important;
    font-weight: 700 !important;
    line-height: 23px;
    width: 80%;
  }
}
