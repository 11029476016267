.providingsContent {
  padding: 30px 0;
}

.providingsContent {
  position: relative;
}

.carouselImage {
  width: 100%;
  height: auto;
}

.slider {
  position: absolute !important;
  width: 100%;
  top: 50% !important;
  left: 50%;
  transform: translate(-50%, -50%);
}

.slider1 {
  position: absolute !important;
  width: 100%;
}

.slider2 {
  position: absolute !important;
  width: 100%;
}

.serviceCard,
.serviceCard1,
.serviceCard2 {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.serviceImage {
  width: 50px !important;
}

.serviceImage1,
.serviceImage2 {
  width: 150px !important;
}

.serviceTitle {
  font-size: 1em !important;
  font-weight: 500;
}

.serviceTitle,
.serviceTitle2,
.serviceTitle1,
.serviceDescription,
.serviceDescription1,
.serviceDescription2,
.btn,
.btn1,
.btn2 {
  font-family: "Poppins", sans-serif;
  color: #fff !important;
}

.serviceTitle1,
.serviceTitle2 {
  font-size: 1.4em !important;
  font-weight: 500;
}

.serviceDescription {
  font-size: 1.3em !important;
  font-weight: 400;
  width: 230px !important;
  text-align: center;
  line-height: 120%;
}

.serviceDescription1,
.serviceDescription2 {
  font-size: 1em !important;
  font-weight: 400;
  width: 230px;
  text-align: center;
  line-height: 140%;
}

.btn,
.btn1,
.btn2 {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #ff7e00;
  border: none;
  border-radius: 5px;
  margin-top: 10px !important;
  font-size: 1.3em;
  padding: 10px 30px;
}

.serviceName {
  width: 200px !important;
  padding-top: 10px;
  margin: auto;
  display: block;
}
.serviceText {
  color: black;
  font-family: "Poppins", sans-serif;
  font-weight: 500;
}
.serviceBtn {
  width: 160px !important;
}

@media screen and (min-width: 1400px) {
  .slider {
    top: 40%;
  }
  .serviceTitle {
    font-size: 1.5em !important;
  }
  .serviceDescription {
    font-size: 1.3em !important;
    width: 350px !important;
  }
  .serviceImage {
    width: 250px !important;
  }
}
@media screen and (min-width: 1700px) {
  .slider {
    top: 47%;
  }
}

@media screen and (max-width: 1350px) {
  .serviceTitle {
    font-size: 1.4em !important;
  }

  .serviceDescription {
    font-size: 1.1em !important;
  }

  .btn {
    margin-top: 10px;
    font-size: 1.1em;
    padding: 10px 30px;
  }
  .slider {
    top: 43%;
    width: 100%;
  }
}

@media screen and (max-width: 1200px) {
  .serviceTitle {
    font-size: 1.6em !important;
  }

  .serviceDescription {
    font-size: 1em !important;
  }

  .btn {
    font-size: 1.1em;
    padding: 10px 30px;
  }
}

@media screen and (max-width: 992px) {
  .slider {
    top: 40%;
  }

  .serviceImage {
    width: 100px !important;
  }

  .serviceTitle {
    font-size: 1.3em !important;
  }

  .serviceDescription {
    font-size: 1em !important;
  }
}

@media screen and (max-width: 768px) {
  .slider1 {
    top: 16%;
  }

  .slider2 {
    top: 56%;
  }

  .serviceImage1,
  .serviceImage2 {
    width: 200px;
    height: 150px;
  }

  .serviceCard {
    width: 100%;
  }

  .serviceTitle {
    font-size: 1.7em !important;
  }

  .serviceDescription {
    font-size: 1.2em !important;
  }

  .btn1,
  .btn2 {
    font-size: 1.2em;
    padding: 10px 45px;
  }
}

@media screen and (max-width: 670px) {
  .slider1 {
    top: 15%;
  }

  .slider2 {
    top: 55%;
  }

  .carouselImage {
    width: 100%;
    height: 350px;
    object-fit: cover;
    object-position: center;
  }

  .serviceImage1,
  .serviceImage2 {
    width: 190px;
    height: 150px;
  }

  .serviceCard {
    width: 100%;
  }

  .serviceTitle {
    font-size: 1.6em !important;
  }

  .serviceDescription {
    font-size: 1.1em !important;
  }
}

@media screen and (max-width: 576px) {
  .slider1 {
    top: 13%;
  }

  .slider2 {
    top: 53%;
  }

  .carouselImage {
    width: 100%;
    height: 350px;
    object-fit: cover;
    object-position: center;
  }

  .serviceImage1,
  .serviceImage2 {
    width: 150px !important;
    height: 150px;
  }

  .serviceCard {
    width: 100%;
  }

  .serviceTitle {
    font-size: 1.8em !important;
  }

  .serviceDescription {
    font-size: 1.1em !important;
  }
}

@media screen and (max-width: 520px) {
  .slider1 {
    top: 13%;
  }

  .slider2 {
    top: 53%;
  }

  .btn1,
  .btn2 {
    font-size: 1em;
    padding: 8px 35px;
  }
}

@media screen and (max-width: 480px) {
  .slider1 {
    top: 13%;
  }

  .slider2 {
    top: 53%;
  }

  .btn1,
  .btn2 {
    font-size: 0.9em;
    padding: 8px 35px;
  }
}

@media screen and (max-width: 425px) {
  .slider1 {
    top: 12.3%;
  }

  .slider2 {
    top: 52.3%;
  }

  .btn1,
  .btn2 {
    font-size: 0.9em;
    padding: 8px 35px;
  }
}

@media screen and (max-width: 385px) {
  .slider1 {
    top: 13%;
  }

  .slider2 {
    top: 53%;
  }

  .btn1,
  .btn2 {
    font-size: 0.8em;
    padding: 8px 35px;
  }
}
