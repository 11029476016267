.imageStyle {
  width: 150px !important;
  height: 200px !important;
  display: block;
  margin: auto;
  position: relative;
  bottom: -10px;
}
/* .imagebg {
  background-image: url("../../Assets/backgroundofpet.png");
  background-size: contain;
} */
.bodyStyle {
  font-weight: 500;
  text-align: center;
  padding: 0 15px 25px 15px;
}
.petName {
  padding: 15px;
  font-size: 1.4em;
  font-weight: 700;
}
.cardStyle {
  width: 350px;
  margin: 20px;
  background-color: #d6e3ff;
  border-radius: 25px;
}
.allcards {
  flex-wrap: nowrap;
  overflow-x: scroll;
  overflow-y: hidden;
}
.allcards::-webkit-scrollbar {
  display: none;
}
.title {
  margin: auto;
  width: 280px;
  display: block;
}
.entireKnowthem {
  /* background-image: url("../../Assets/Knowthemvector.png");
  background-size: cover; */
  padding: 50px 0px;
}
@media screen and (max-width: 768px) {
  .cardStyle {
    width: 200px;
    margin: 5px;
  }
  .petName {
    font-size: 0.85em;
  }
  .text {
    font-size: 0.8em;
  }
  .entireKnowthem {
    background-image: url("../../Assets/knowthemMobilevector.png");
    background-size: cover;
  }
  .title {
    margin: auto;
    width: 200px;
    display: block;
  }
}
