.blogTitle,
.petNews,
.blogdesc,
.text {
  font-family: "Poppins", sans-serif;
}
.petNews,
.date {
  font-size: 1.3vw;
  font-weight: 500;
}

.mainImage {
  width: 650px;
  height: 510px;
}

.mainSection {
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
}

.blogdesc {
  font-size: 22px !important;
  line-height: 160% !important;
  letter-spacing: 4% !important;
}
.petNews {
  text-decoration: underline dashed brown;
  text-underline-offset: 10px;
}
.blogTitle {
  font-size: 32px !important;
  letter-spacing: -2.2%;
  font-weight: 600;
}

.latestUpdates {
  background-color: #3a60a1;
  padding: 7px;
  border-top-left-radius: 7px;
  border-top-right-radius: 7px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.latestUpdates .latest {
  font-size: 24px !important;
  letter-spacing: -2.2%;
  color: #fff;
  font-weight: 500;
}

.textContainer {
  padding: 10px;
}

.blogImageContainer {
  position: relative;
  max-height: 180px;
  overflow: hidden;
}

.blogImage {
  /* width: 100% !important; */
  max-width: 100%;
  height: auto;
}

.blogScroll {
  max-height: 590px;
  overflow-y: auto;
}

.dateBlog {
  position: absolute;
  font-family: "Poppins", sans-serif;
  top: 10px;
  right: 16px;
  font-size: 11px;
  font-weight: bold;
  padding: 2px 4px;
  border-radius: 5px;
  color: #000;
  background-color: rgba(255, 254, 254, 0.5);
}

.textContent {
  padding: 15px;
}

.paraText {
  font-size: 18px !important;
  font-weight: 500 !important;
  font-family: "Poppins", sans-serif !important;
  line-height: 150% !important;
  letter-spacing: -2.2% !important;
  margin-bottom: 0 !important;
}

.readMore {
  border: none;
  color: blue;
  font-weight: 700;
}
.lgreadMore {
  border: none;
  color: blue;
  font-weight: 700;
  font-size: 1.4vw;
  width: 100%;
}
.text {
  font-size: 1.2vw;
  font-weight: 500;
}
.cardStyle {
  border: 1px solid grey;
  box-shadow: 3px white;
}
.title {
  margin: auto;
  width: 200px;
  display: block;
}

.blogContainer {
  /* background-image: url("../../Assets/Blog/Blogvector.png"); */
  background-position: cover;
  background-repeat: no-repeat;
  margin-top: 35px;
  margin-bottom: 35px;
}
.mobilevector {
  /* background-image: url('../../Assets/Blog/mobileblogvector.png'); */
  background-position: contain;
  background-repeat: no-repeat;
  padding: 100px 0 0 0;
}
.allblogs {
  overflow-x: auto;
}
.allblogs::-webkit-scrollbar {
  display: none;
}
.blogcards {
  position: absolute;
  top: 85%;
}

@media screen and (max-width: 1400px) {
  .mainImage {
    width: 610px;
    height: 470px;
  }

  .blogdesc {
    font-size: 20px !important;
    line-height: 160% !important;
    letter-spacing: 3% !important;
  }

  .blogTitle {
    font-size: 30px !important;
    letter-spacing: -2.2%;
    font-weight: 600;
  }

  .blogScroll {
    max-height: 560px;
    overflow-y: auto;
  }

  .blogcards {
    position: absolute;
    top: 90%;
  }
}

@media screen and (max-width: 1200px) {
  .mainImage {
    width: 560px;
    height: 420px;
  }

  .blogdesc {
    font-size: 18px !important;
    line-height: 160% !important;
    letter-spacing: 3% !important;
  }

  .blogTitle {
    font-size: 28px !important;
    letter-spacing: -2.2%;
    font-weight: 600;
  }

  .blogScroll {
    max-height: 515px;
    overflow-y: auto;
  }

  .blogcards {
    position: absolute;
    top: 90%;
  }
}

@media screen and (max-width: 1100px) {
  .mainImage {
    width: 480px;
    height: 340px;
  }

  .blogdesc {
    font-size: 18px !important;
    line-height: 145% !important;
    letter-spacing: 3% !important;
  }

  .blogTitle {
    font-size: 27px !important;
    letter-spacing: -2.2%;
    font-weight: 600;
  }

  .blogScroll {
    max-height: 470px;
    overflow-y: auto;
  }

  .blogcards {
    position: absolute;
    top: 90%;
  }
}

@media screen and (max-width: 992px) {
  .mainImage {
    width: 440px;
    height: 300px;
  }

  .blogdesc {
    font-size: 16px !important;
    line-height: 145% !important;
    letter-spacing: 3% !important;
  }

  .blogTitle {
    font-size: 25px !important;
    letter-spacing: -2.2%;
    font-weight: 600;
  }

  .blogScroll {
    max-height: 380px;
    overflow-y: auto;
  }

  .blogImageContainer {
    max-height: 125px;
  }
  .latestUpdates {
    padding: 5px;
  }

  .latestUpdates .latest {
    font-size: 19px !important;
  }

  .blogImage {
    max-width: 100%;
    height: auto;
  }

  .dateBlog {
    top: 7px;
    right: 15px;
    font-size: 9.5px;
    padding: 2px 4px;
  }

  .textContent {
    padding: 13px;
  }

  .paraText {
    font-size: 15px !important;
  }

  /* .readMore {
    border: none;
    color: blue;
    font-weight: 700;
  } */

  .blogcards {
    position: absolute;
    top: 90%;
  }
}

@media screen and (max-width: 883px) {
  .mainImage {
    width: 380px;
    height: 270px;
  }

  .blogdesc {
    font-size: 15px !important;
    line-height: 145% !important;
    letter-spacing: 3% !important;
  }

  .blogTitle {
    font-size: 24px !important;
    letter-spacing: -2.2%;
    font-weight: 600;
  }

  .blogScroll {
    max-height: 380px;
    overflow-y: auto;
  }

  .blogImageContainer {
    max-height: 125px;
  }

  .latestUpdates {
    padding: 5px;
  }

  .latestUpdates .latest {
    font-size: 18px !important;
  }

  .dateBlog {
    top: 7px;
    right: 15px;
    font-size: 9px;
    padding: 2px 4px;
  }

  .textContent {
    padding: 13px;
  }

  .paraText {
    font-size: 14px !important;
  }

  .blogcards {
    position: absolute;
    top: 90%;
  }
}

@media screen and (max-width: 768px) {
  .blogImageContainer {
    position: relative;
    max-height: 100%;
  }

  .blogImage {
    /* width: 100% !important; */
    max-width: 100%;
    height: auto;
  }

  .blogScroll {
    /* max-height: 590px; */
    overflow-y: auto;
  }

  .dateBlog {
    top: 10px;
    right: 20px;
    font-size: 10px;
    font-weight: bold;
    padding: 2px 4px;
  }

  .textContent {
    padding: 15px;
  }

  .paraText {
    font-size: 16px !important;
    line-height: 150% !important;
    letter-spacing: -2.2% !important;
    margin-bottom: 0 !important;
  }
}

@media screen and (max-width: 576px) {
  .blogImageContainer {
    position: relative;
    max-height: 290px;
  }

  .blogImage {
    /* width: 100% !important; */
    max-width: 100%;
    height: auto;
  }

  .blogScroll {
    /* max-height: 590px; */
    overflow-y: auto;
  }

  .dateBlog {
    top: 10px;
    right: 20px;
    font-size: 10px;
    font-weight: bold;
    padding: 2px 4px;
  }

  .textContent {
    padding: 15px;
  }

  .paraText {
    font-size: 16px !important;
    line-height: 150% !important;
    letter-spacing: -2.2% !important;
    margin-bottom: 0 !important;
  }
}
