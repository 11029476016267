.partnerbtn {
    background: black;
    color: #fff;
    margin-top: 1em;
    border-radius: 5px;
    cursor: pointer;
    font-family: "Poppins", sans-serif;
    padding: 10px;
    font-weight: 500;
    width: 220px;
    border: none;
    font-size: 1.3em;
  }
  .partnerbtn:hover{
    background-color: white;
    color: black;
  }
  .btn{
    position: absolute;
    top: 80%;
    right:25%;
  }
  @media screen and (max-width: 992px) {
    .partnerbtn{
      font-size: 1em;
      width: 180px;
    }
  }
  @media screen and (max-width: 768px) {
    .partnerbtn{
      font-size: 0.8em;
      width: 100px;
    }
    .btn{
      top: 78%;
    }
  }
  @media screen and (max-width: 576px) {
    .partnerbtn{
      font-size: 0.6em;
      width: 75px;
      padding: 5px;
    }
    .btn{
      top: 75%;
    }
  }