.sectionTitle {
  padding: 10px 10px 10px 0;
  font-size: 1.4em;
  font-weight: 500;
}

.logo {
  height: 150px !important;
  width: 170px !important;
}

.sectionTitle,
.pageName,
.location,
.locationText {
  font-family: "Poppins", sans-serif;
}

.sectionTitle {
  font-size: 20px !important;
}
.pageName {
  color: rgb(200, 200, 200);
  font-size: 16px;
  display: flex;
  align-items: center;
}
.location {
  /* color: rgb(200, 200, 200); */
  font-size: 16px;
  text-align: left;
  display: flex;
  justify-content: center;
}
.copyright {
  font-size: 18px !important;
  color: white;
  font-family: "Poppins", sans-serif;
}
.allpets {
  position: relative;
  display: flex;
  justify-content: space-around;
  height: 75px;
  background-color: white;
  background-image: url("../../Assets/pattern\ 1.png");
  background-size: cover;
}
.footerStyle {
  text-align: center;
  color: #fff;
  font-size: 10px;
  font-family: "Poppins", sans-serif;
  background-color: #000;
  padding: 24px 20px;
  padding-top: 30px;
  z-index: 1;
}
.eachpet {
  transition: top 0.25s ease;
  cursor: pointer;
  position: absolute;
  top: 0px;
}
.eachpet:hover {
  top: -50px;
}
.divider {
  background-color: white;
}

@media screen and (max-width: 1250px) {
  .sectionTitle {
    font-size: 18px !important;
  }

  .pageName {
    font-size: 15px;
  }

  .location,
  .locationText {
    font-size: 15px;
    color: rgb(200, 200, 200);
  }

  .logo {
    height: 140px !important;
    width: 150px !important;
  }
}

@media screen and (max-width: 1150px) {
  .sectionTitle {
    font-size: 17px !important;
  }

  .pageName {
    font-size: 14px;
  }

  .location {
    font-size: 14px;
  }

  .logo {
    height: 130px !important;
    width: 140px !important;
  }
}

@media screen and (max-width: 992px) {
  .logo {
    height: 150px !important;
    width: 170px !important;
  }

  .sectionTitle {
    font-size: 20px !important;
  }

  .location,
  .locationText {
    font-size: 16px;
  }

  .pageName {
    font-size: 16px;
  }

  .copyright {
    font-size: 16px !important;
    color: white;
    font-family: "Poppins", sans-serif;
  }
}
@media screen and (max-width: 768px) {
  .logo {
    height: 120px !important;
    width: 140px !important;
  }

  .sectionTitle {
    font-size: 20px !important;
  }

  .location,
  .locationText {
    font-size: 15px;
  }

  .pageName {
    font-size: 15px;
  }

  .copyright {
    font-size: 15px !important;
    color: white;
    font-family: "Poppins", sans-serif;
  }
}
@media screen and (max-width: 576px) {
  .divider {
    display: none;
    background-color: white;
  }

  .logo {
    height: 130px !important;
    width: 150px !important;
    margin-bottom: 15px;
  }

  .sectionTitle {
    font-size: 20px !important;
  }

  .location,
  .locationText {
    font-size: 15px;
    width: 80%;
  }

  .pageName {
    font-size: 15px;
  }

  .copyright {
    font-size: 14px !important;
    color: white;
    font-family: "Poppins", sans-serif;
  }
}
