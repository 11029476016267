.headerStyle {
  display: flex;
  /* justify-content: space-between; */
  align-items: center;
  background: none;
  height: 7em;
  padding: 0 3em;
  z-index: 10;
  position: fixed;
  top: 0;
  width: 100%;
}
.scrolled {
  background: black !important;
}
.hidden {
  top: -7em; /* Adjust the height of the header */
}

.menu {
  display: flex;
  justify-content: center;
  flex: 1;
  min-width: 0;
  font-size: 1.2em;
  font-weight: 500;
  color: white;
  border-bottom: none;
}
.logo{
  color: white;
}

@media only screen and (max-width: 1200px) {
  .headerStyle {
    height: 5.5em;
    padding: 0 2.5em;
  }

  .menu {
    font-size: 1.33vw;
  }
}

@media only screen and (max-width: 992px) {
  .headerStyle {
    height: 4.8em;
    padding: 0 1.8em;
  }

  .menu {
    font-size: 1.32vw;
  }
}

@media only screen and (max-width: 890px) {
  .headerStyle {
    height: 4.8em;
    padding: 0 1.8em;
  }

  .menu {
    font-size: 1em;
    width: 50px;
  }
}

@media only screen and (max-width: 576px) {
  .headerStyle {
    padding: 0 0 0 1.8em;
  }
}
