.onboardContent {
  padding: 30px;
}

.numberContainer {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.number {
  font-family: "Poppins", sans-serif;
  font-size: 95px;
  font-weight: bold;
  color: gray;
  /* align-self: flex-start; */
  height: fit-content;
}

.text_1 {
  font-size: 27px;
  font-weight: bold;
  line-height: 120%;
  letter-spacing: -2.2%;
  margin-bottom: 10px;
}

.text_2 {
  font-size: 22px;
  line-height: 130%;
  letter-spacing: -2.2%;
}

.onSelect {
  transition: all 0.3s ease-out allow-discrete;
  background-color: #ebf4ff;
  border-left: 10px solid #4e70aa;
}

.onUnSelect {
  border-left: 10px solid transparent;
}

.imageContainer {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.numberRow {
  padding: 25px !important;
  padding-left: 10px !important;
}

@media screen and (max-width: 1300px) {
  .onboardContent {
    padding: 30px;
  }

  .number {
    font-size: 90px;
  }

  .text_1 {
    font-size: 25px;
  }

  .text_2 {
    font-size: 20px;
  }
}

@media screen and (max-width: 1200px) {
  .numberRow {
    padding: 15px !important;
    padding-left: 10px !important;
  }

  .image {
    width: 100% !important;
    /* height: 400px !important;
    object-fit: cover; */
  }

  .onboardContent {
    padding: 30px;
  }

  .number {
    font-size: 85px;
  }

  .text_1 {
    font-size: 23px;
  }

  .text_2 {
    font-size: 18px;
  }
}

@media screen and (max-width: 576px) {
  .numberRow {
    padding: 15px !important;
    padding-left: 15px !important;
  }

  .image {
    width: 100% !important;
    /* height: 400px !important;
    object-fit: cover; */
  }

  .onboardContent {
    padding: 20px;
  }

  .number {
    font-size: 85px;
  }

  .text_1 {
    font-size: 21px;
  }

  .text_2 {
    font-size: 16px;
  }

  .details {
    padding-left: 10px;
  }

  .numberContainer {
    padding-right: 10px;
  }
}
