.privacyContent {
  background-image: url(../../Assets/PrivacyReservation/banner.png);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  background-origin: content-box;
  padding-top: 30px;
  padding-bottom: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.mainPart {
  padding: 30px;
  border-radius: 8px;
  background-color: rgba(0, 0, 0, 0.5);
}

.title {
  margin: 0 !important;
  text-align: center;
  color: #fff !important;
  font-size: 32px !important;
  line-height: 160% !important;
}

.text {
  color: #fff !important;
  font-size: 20px !important;
  line-height: 160% !important;
  font-weight: 300;
}

.card {
  /* width: 100%; */
  background-color: #1a1a1a;
  padding: 20px 20px 16px 20px;
  margin-bottom: 5px;
  transition: all 0.3s;
  border-radius: 5px;
}

.card:hover {
  transform: translateY(-10px);
  background-color: #000;
}

.iconSet {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 20px;
}

.icon {
  width: 40px !important;
  height: 40px !important;
  display: inline-block !important;
  margin-right: 15px;
}

.iconText {
  color: #fff;
  font-size: 26px !important;
  line-height: 150% !important;
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  letter-spacing: -2.2% !important;
}

.descriptionContainer {
  display: flex;
  justify-content: center;
  align-items: center;
}

.description {
  font-size: 18px !important;
  font-family: "Poppins", sans-serif !important;
  line-height: 160%;
  color: #fff;
  width: 80%;
  font-weight: 200 !important;
}

@media only screen and (max-width: 1500px) {
  .description {
    font-size: 17px !important;
    width: 95%;
  }
}

@media only screen and (max-width: 1200px) {
  .title {
    font-size: 30px !important;
  }

  .text {
    font-size: 18px !important;
  }

  .iconText {
    font-size: 24px !important;
  }

  .description {
    font-size: 17px !important;
    width: 100%;
  }
}
