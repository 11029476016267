.reservationContent {
  padding: 30px;
}

.imageContainer {
  /* width: 100%; */
  display: flex;
  justify-content: center;
  align-items: center;
}

.carouselImage {
  /* padding-left: 60px; */
  max-width: 100% !important;
  height: 100% !important;
  object-fit: cover;
}

.textContainer {
  padding: 15px;
  /* margin-left: -60px; */
}

.heading {
  font-size: 32px;
  font-family: "Poppins", sans-serif;
  font-weight: 200;
  line-height: 150%;
  letter-spacing: 2.2%;
  margin-bottom: 15px;
}

.titleContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.title {
  font-size: 24px;
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  line-height: 150%;
  letter-spacing: 2.2%;
  margin-bottom: 6px;
  width: 100%;
}

.descriptionContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.description {
  font-size: 20px;
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  line-height: 150%;
  letter-spacing: 2.2%;
  width: 100%;
}

.buttonContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 30px;
}

.buttonContainer button {
  width: 240px;
  padding: 7px;
  font-size: 20px;
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  line-height: 150%;
  letter-spacing: 2.2%;
  border: none;
  background-color: #000;
  color: #fff;
  border-radius: 5px;
}

.slider {
  position: relative !important;
  width: 100%;
}

@media only screen and (max-width: 1400px) {
  .textContainer {
    padding: 15px;
    /* margin-left: -60px; */
  }

  .heading {
    font-size: 30px;
    margin-bottom: 14px;
  }

  .title {
    font-size: 22px;
    margin-bottom: 6px;
    /* width: 80%; */
  }

  .description {
    font-size: 18px;
    /* width: 90%; */
  }

  .buttonContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    /* margin-top: 35px; */
  }

  .buttonContainer button {
    width: 220px;
    padding: 7px;
    font-size: 18px;
  }
}

@media only screen and (max-width: 1300px) {
  .textContainer {
    padding: 15px;
  }

  .heading {
    font-size: 28px;
    margin-bottom: 12px;
  }

  .title {
    font-size: 20px;
    margin-bottom: 4px;
  }

  .description {
    font-size: 17px;
  }

  .buttonContainer {
    margin-top: 20px;
  }

  .buttonContainer button {
    width: 200px;
    padding: 7px;
    font-size: 16px;
  }
}

@media only screen and (max-width: 1200px) {
  .textContainer {
    padding: 12px;
  }

  .heading {
    font-size: 27px;
    margin-bottom: 12px;
  }

  .carouselImage {
    padding-left: 15px;
    height: 360px !important;
    min-width: 480px !important;
    /* object-fit: cover !important; */
  }

  .title {
    font-size: 19px;
    margin-bottom: 6px;
  }

  .description {
    font-size: 16px;
  }

  .buttonContainer {
    margin-top: 18px;
  }

  .buttonContainer button {
    width: 200px;
    padding: 7px;
    font-size: 16px;
  }
}

@media only screen and (max-width: 992px) {
  .reservationContent {
    padding: 10px;
  }

  .textContainer {
    padding: 12px;
  }

  .heading {
    font-size: 27px;
    margin-bottom: 12px;
  }

  .carouselImage {
    padding-left: 15px;
    height: auto !important;
    width: 100% !important;
    /* object-fit: cover !important; */
  }

  .title {
    font-size: 19px;
    margin-bottom: 6px;
  }

  .description {
    font-size: 16px;
  }

  .buttonContainer {
    margin-top: 18px;
  }

  .buttonContainer button {
    width: 200px;
    padding: 7px;
    font-size: 16px;
  }
}
