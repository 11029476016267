/* .entireLocations {
  background: url("../../Assets/Locations/ourLocationsVector.png");
  background-size: 100% 100%;
  padding: 50px 0px;
}
.box {
  width: 350px;
  height: 400px;
  border: 1px solid #ccc;
  overflow: hidden;
  position: relative;
  margin-right: 20px;
  display: flex;
  justify-content: flex-end;
  flex-direction: column;
  align-items: center;
  border-radius: 50px 50px 0 0;
  background-color: #f1f1f1;
  background-image: url("../../Assets/Locations/LocationBackground.png");
}
.clipper {
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 50px;
  overflow: hidden;
  background-color: #581203;
  color: white;
  font-weight: bold;
  font-size: large;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
.locationName {
  position: absolute;
  top: 10%;
  font-weight: bold;
  color: #581203;
  font-size: 1.5em;
  transition: transform 1.25s ease, width 0.5s ease;
}

.rotatingImage {
  position: absolute;
  bottom: -140px;
  width: 400px;
  height: 400px;
  transition: transform 1.25s ease, width 0.5s ease;
}
.raisingpet {
  position: absolute;
  opacity: 0;
  bottom: 53px;
  height: 50px;
  transition: transform 1.5s ease, opacity 1.5s ease, bottom 0.5s ease;
}

.box.hovered .rotatingImage {
  transform: rotate(150deg) scale(1.5);
}
.box.hovered .locationName {
  transform: scale(1.25);
  transition: transform 1s ease;
}
.box.hovered .clipper {
  color: #ff8107;
}
.box.hovered .raisingpet {
  transform: scale(1.5);
  opacity: 1;
}
.locationTitle {
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 280px;
}
.locationCards {
  overflow-x: scroll;
  flex-wrap: nowrap;
  width: 100%;
}
.locationCards::-webkit-scrollbar {
  display: none;
}

@media screen and (max-width: 576px) {
  .box {
    width: 300px;
    height: 400px;
  }
  .rotatingImage {
    position: absolute;
    bottom: -100px;
    width: 300px;
    height: 300px;
    transform: rotate(150deg) scale(1.5);
  }
  .raisingpet {
    position: absolute;
    bottom: 53px;
    height: 35px;
    transition: transform 1.5s ease, opacity 1.5s ease, bottom 0.5s ease;
    opacity: 1;
    transform: scale(1.5);
  }
}
@media screen and (max-width: 768px) {
  .entireLocations {
    background-image: url('../../Assets/Locations/ourlocationsmobilevector.png');
  }
  .locationTitle {
    width: 200px;
  }
} */

.title {
  font-weight: 800;
  font-size: 2.5em;
  display: block;
  font-family: Poppins, sans-serif;
  margin-bottom: 20px;
}
.paragraph {
  display: block;
  font-weight: 300;
  font-size: 1.3em;
  font-family: Poppins, sans-serif;
}
.overlay {
  position: absolute;
  top: 0;
  left: 0;
  right:0;
  width:100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5); /* Adjust opacity as needed */
  display: flex;
  border-radius: 15px;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.overlay p{
  color: white;
  font-size: 1.4rem;
  font-weight: 500;
  font-family: Poppins, sans-serif;
}
@media screen and (max-width: 1024px) {
  .title {
    font-size: 2em;
  }
  .paragraph {
    font-size: 1.2em;
  }
}
@media screen and (max-width: 992px) {
  .title {
    font-size: 1.5em;
  }
  .paragraph {
    font-size: 1em;
  }
}
@media screen and (max-width: 767px) {
  .title {
    font-size: 2.5em;
  }
  .paragraph {
    font-size: 1.3em;
  }
  .overlay p, .overlayPune p {
    font-size: 17px;
  }
}
@media screen and (max-width: 576px) {
  .title {
    font-size: 2em;
  }
  .paragraph {
    font-size: 1.3em;
  }
}
